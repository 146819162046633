<div class="banner">
    <div class="jumbotron" style="text-align: center">
        <h1>SDG Research</h1>
    </div>
</div>

<div class="container-fluid content-container">
    <div class="content">
        <div class="grid">
            <div class="sm:col-12 md:col-12 lg:col-11 xl:col-8 lg:col-offset-1 xl:col-offset-2">
                <div class="grid flex">
                    <div class="sm:col-12 md:col-6 lg:col-6 xl:col-6 centered" style="padding: 30px;">
                        <div style="width: 100%; max-width: 450px;">
                        <a href="https://sdgs.un.org/goals"><img src="assets/img/sdg_logo_large.png"
                                                                 style="width: 100%; max-width: 450px;" > </a>
                        <a href="https://sdgs.un.org/goals"><img src="assets/img/sdg_icon_list.png"
                                                                 style="width: 100%; max-width: 450px;"></a>
                        </div>
                    </div>
                    <div class="sm:col-12 md:col-6 lg:col-6 xl:col-6">
                        <p>Aurora is a partnership of like-minded and closely collaborating research intensive European universities, who use their academic excellence to drive societal change and contribute to the sustainable development goals (SDGs). The SDG Research Dashboard enables you to see the research publications that relate to the Global Goals, how open and freely available these are to society, and what (non-)governmental organisations make use of these publications in their policy.</p>

                        <p>Below you’ll find tiles to the SDG Research Dashboard, along with the user guides on how to use the dashboard. Also we made some indroduction and explainer video’s, and a manual how to build an SDG dashboard your self.</p>

                        <p>New is the SDG classifier and SDG badges, where you can dynamically generate an SDG wheel based on a text fragment in 104 languages, and code to embed on an web page.</p>
                    </div>
                </div>
                <div class="grid flex">
                    <div class="sm:col-12 md:col-12 lg:col-4 xl:col-4 centered">
                        <div class="about-tile">
                        </div>
                    </div>
                    <div class="sm:col-12 md:col-12 lg:col-4 xl:col-4 centered">
                        <div class="about-tile">

                        </div>
                    </div>
                    <div class="sm:col-12 md:col-12 lg:col-4 xl:col-4 centered">
                        <div class="about-tile">

                        </div>
                    </div>
                    <div class="sm:col-12 md:col-12 lg:col-4 xl:col-4 centered">
                        <div class="about-tile">

                        </div>
                    </div>
                    <div class="sm:col-12 md:col-12 lg:col-4 xl:col-4 centered">
                        <div class="about-tile">

                        </div>
                    </div>
                    <div class="sm:col-12 md:col-12 lg:col-4 xl:col-4 centered">
                        <div class="about-tile">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
