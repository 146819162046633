  export const availableModels = [
  {
    name: 'Aurora SDG mBERT model (slower, Aurora definition of SDG\'s, 104 languages)',
    value: 'aurora-sdg',
    description: 'The <a href="https://doi.org/10.5281/zenodo.5835849">"Aurora SDG mBERT model" consists of 17 separate classifier models</a> – each of them being trained using 1.4 million abstracts, using the SDG definition according to the <a href="https://aurora-network-global.github.io/sdg-queries/">Aurora SDG queries v5</a>. At the end of the process, you receive results from all 17 classifiers at once. The result is a prediction that ranges from 0 to 1 for each SDG goal.'
  },
  {
    name: 'Aurora SDG multi-label mBERT model (fast, Aurora definition of SDG\'s, 104 languages)',
    value: 'aurora-sdg-multi',
    description: 'The "Aurora SDG multi-label mBERT model" is one smaller and faster model, and has been trained using 1.4 million abstracts using the SDG definition according to the <a href="https://aurora-network-global.github.io/sdg-queries/">Aurora SDG queries v5</a>, and outputs all the 17 probabilities itself. Due to the differences in architecture and training data balance, the results from both Aurora models differ slightly. The result is a prediction that ranges from 0 to 1 for each SDG goal.'
  },
  {
    name: 'Elsevier SDG multi-label mBERT model (fast, Elsevier definition of SDG\'s, 104 languages)',
    value: 'elsevier-sdg-multi',
    description: 'The <a href="https://doi.org/10.5281/zenodo.7095783">"Elsevier SDG multi-label mBERT model"</a> has been trained using millions of abstracts using the SDG definition according to the <a href="https://doi.org/10.17632/6bjy52jkm9.1">Elsevier SDG queries 2022</a>, This has been tested with their subject mater experts, and used in the <a href="https://www.timeshighereducation.com/world-university-rankings/impact-rankings-2022-methodology">Times Higher Education</a> Impact Ranking. The result is a prediction that ranges from 0 to 1 for each SDG goal.'
  },
  {
    name: 'OSDG model (alternative, OSDG definition of SDG\'s, 15 languages)',
    value: 'osdg',
    description: 'The <a href="https://github.com/osdg-ai/osdg-tool/tree/pre-release">"OSDG model"</a> is not an mBERT model, but uses another ML approach; topic matching on Fields of Science. This is been tested by a <a href="https://osdg.ai/community">community of 2000+ volunteers</a> organised through their <a href="https://osdg.ai/about">OSDG consortium</a>. The result is not a prediction that ranges from 0 to 1, but a 1 with a matching SDG, and 0 if there is no match. We put this model in our service for reference.'
  }
]

export const serviceBaseAddress = 'aurora-sdg.labs.vu.nl'
