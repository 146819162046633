import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ClassificationResponse} from "../model/ClassificationResponse";
import {serviceBaseAddress} from "../app/app.globals";


@Injectable()
export class ClassifierService {

  private classifierHost = serviceBaseAddress

  constructor(private http: HttpClient) {
  }

  public runClassifierOnSingleText(text: string, model: string): Observable<ClassificationResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<ClassificationResponse>('https://' + this.classifierHost + '/classifier/classify/' + model, {'text': text}, {headers: headers});
  }

  public getUrl(text: string, model: string): string {
    return 'https://' + this.classifierHost + '/classifier/classify/' + model + '?text=' + encodeURI(text)
  }
}
