import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AboutComponent} from "./about.component";
import {OpenaireComponent} from "./openaire.component";

const routes: Routes = [
  {path: '', redirectTo: 'sdg-classifier/text', pathMatch: 'full'},
  {path: 'about', component: AboutComponent},
  {path: 'home', component: AboutComponent},
  {path: 'openaire', component: OpenaireComponent},
  {path: 'about', loadChildren: () => import('./about/about.module').then(m => m.AboutModule)},
  {path: 'sdg-classifier',loadChildren: () => import('./classifier/classifier.module').then(m => m.ClassifierModule)},
  {path: 'dashboard',loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)}
]

@NgModule({
  imports: [RouterModule.forRoot(routes,{ anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
