<nav *ngIf="!frameless" class="navbar navbar-expand-lg navbar-light fixed-top" style="background-color: white;">
  <div class="navbar-brand">
    <a href="https://aurora-universities.eu/">
      <img src="assets/img/aurora_logo_unicorn_CROPPED.png" height="30" alt="Aurora-Logo" style="margin-left: 20px;">
    </a>
  </div>
  <div class="ml-auto" style="padding-right: 100px;">
    <p-menubar [autoDisplay]="true" [model]="menuItems"></p-menubar>
  </div>
</nav>

<router-outlet></router-outlet>
