import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import {FormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {InputTextareaModule} from "primeng/inputtextarea";
import {InputTextModule} from "primeng/inputtext";
import {ButtonModule} from "primeng/button";
import {FileUploadModule} from "primeng/fileupload";
import {ProgressBarModule} from "primeng/progressbar";
import {DropdownModule} from "primeng/dropdown";
import {ClassifierService} from "../service/classifier.service";
import {HttpClientModule} from "@angular/common/http";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {DialogModule} from "primeng/dialog";
import {ChartModule} from "primeng/chart";
import {MessageModule} from "primeng/message";
import {MessageService} from "primeng/api";
import {ClipboardModule, ClipboardService} from "ngx-clipboard";
import {InputNumberModule} from "primeng/inputnumber";
import {AboutComponent} from "./about.component";
import {MenuModule} from "primeng/menu";
import {MenubarModule} from "primeng/menubar";
import {AppRoutingModule} from "./app-routing.module";
import {CommonsModule} from "./commons.module";
import {OpenaireComponent} from "./openaire.component";

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
      OpenaireComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    InputTextareaModule,
    InputTextModule,
    MenuModule,
    MenubarModule,
    ButtonModule,
    HttpClientModule,
    FileUploadModule,
    DialogModule,
    ChartModule,
    InputNumberModule,
    MessageModule,
    ProgressBarModule,
    DropdownModule,
    ClipboardModule,
    BrowserAnimationsModule,
    CommonsModule
  ],
  providers: [ClassifierService,HttpClientModule, ClipboardService, MessageService],
  bootstrap: [AppComponent]
})
export class AppModule { }
