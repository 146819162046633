<div class="banner">
    <div class="jumbotron" style="text-align: center">
        <h1>OpenAIRE</h1>
    </div>
</div>

<div class="container-fluid content-container">
    <div class="content">
        <div class="grid">
            <div class="sm:col-12 md:col-10 lg:col-8 xl:col-6 md:col-offset-1 lg:col-offset-2 xl:col-offset-3">
                <div class="grid flex">
                    <div class="col-12">
                        <p>Aurora and OpenAIRE signed a <a href="https://aurora-universities.eu/aurora-and-openaire-join-forces-on-open-science-and-sdgs/">memorandum of understanding</a> to join forces on Open Science and SDG's.</p>
                        <p>The metadata content of the publications from Aurora Universities are ingested in the <a href="https://graph.openaire.eu/">OpenAIRE Research Graph</a>. This content will be enriched and accessed throught a Gateway, and monitor the progress on Open Science and impact with a Monitoring dashboard.</p>
                    </div>
                    <div class="sm:col-12 md:col-6 lg:col-6 xl:col-6 centered">
                        <a href="https://aurora.openaire.eu/"><img src="assets/img/openaire_connect_logo.png" style="width: 100%; max-width: 400px;">
                            <p style="font-size: larger;">Aurora Research Gateway</p>
                        </a>
                    </div>
                    <div class="sm:col-12 md:col-6 lg:col-6 xl:col-6 centered">
                        <a href="https://aurora.openaire.eu/"><img src="assets/img/openaire_monitor_logo.png" style="width: 100%; max-width: 400px;">
                            <p style="font-size: larger;">Aurora Research Gateway</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="content">
        <div class="grid">
            <div class="sm:col-12 md:col-12 lg:col-12 xl:col-10 xl:col-offset-1">
                <div class="grid flex">
                    <div class="col-12">
                        <h1 class="results-heading" id="sdg-to-research-2">Aurora and OpenAIRE: CONNECTing research and SDGs</h1>
                    </div>
                    <div class="sm:col-12 md:col-6 lg:col-6 xl:col-6 centered" style="padding: 20px;">
                        <div class="sdg-video" style="max-width: 1000px;">
                        <iframe sandbox="allow-scripts allow-popups allow-forms allow-same-origin allow-popups-to-escape-sandbox allow-downloads allow-modals" aria-label="YouTube Video, Introduction to the Aurora SDG Research Dashboard" src="https://www.youtube.com/embed/5NqLAFwxnUk" allowfullscreen="" data-dashlane-frameid="351"></iframe>
                        </div>
                    </div>
                    <div class="sm:col-12 md:col-6 lg:col-6 xl:col-6">
                        <p>OpenAIRE CONNECT enables institutions, universities, or lead teams on a scientific domain, to easily create, configure and manage their own customised web portals (aka gateways) for the discovery of research outcomes that are of interest to their audiences.</p>

                        <p>Thanks to its high configurability, a CONNECT Gateway can be customized with the community identity and be a single entry point to the research products relevant for the community among everything that is available in the OpenAIRE Research Graph.</p>

                        <p>Monitoring the contribution to SDGs (Sustainable Development Goals) is now easier than ever thanks to the specific filter, but more amazing features are yet to come thanks to the collaboration between Aurora and OpenAIRE.</p>

                        <p>Aurora is a partnership of like-minded and closely collaborating research intensive European universities, who use their academic excellence to drive societal change and contribute to the sustainable development goals.</p>

                        <p>Discover the Aurora gateway (https://aurora.openaire.eu) and how the teams are collaborating to monitor Open Science and the research impact on SDGs.</p>

                        <p>(source: <a href="https://www.openaire.eu/oaweek2022#programme">https://www.openaire.eu/oaweek2022#programme</a> )</p>

                        <h4>Presentations and recordings:</h4>

                        <ul>
                            <li>
                                Bardi, Alessia, & Vanderfeesten, Maurice. (2022, October 25). OAWeek: Aurora and OpenAIRE: CONNECTing research and SDG Aurora and OpenAIRE: CONNECTing research and SDG. Zenodo.
                                <p><a href="https://knowsdgs.jrc.ec.europa.eu/sdgmapper">https://doi.org/10.5281/zenodo.7249431</a></p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
