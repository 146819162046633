import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  public menuItems = [{
    label: 'Dashboard',
    routerLink: ['dashboard/view'],
    items: [
      { label: 'View the dashboard',
        routerLink: ['dashboard/view']},
      {label: 'How to use the dashboard',
        routerLink: ['dashboard/howto']},
      {label: 'Build your own dashboard',
        routerLink: ['dashboard/own-dashboard']},
      {label: 'Previous SDG dashboard',
       routerLink: ['/dashboard/previous']}
    ]
  }, {
    label: 'Classifier',
    routerLink: ['sdg-classifier/text'],
    items: [
      {
        label: 'Classify your text',
        routerLink: ['sdg-classifier/text'],
      },
      {
        label: 'How to use the classifier',
        routerLink: ['sdg-classifier/how-to']
      },
      {
        label: 'SDG badges',
        routerLink: ['sdg-classifier/badge']
      },
      {
        label: 'Use the API',
        routerLink: ['sdg-classifier/api']
      }
    ]
  },{
    label: 'Aurora@OpenAIRE',
    routerLink: ['/openaire'],
    items: [
      {
        label: 'Dashboard',
        icon: 'pi pi-external-link',
        url: 'https://aurora.openaire.eu/'
      },
      {
        label: 'Monitor',
        icon: 'pi pi-external-link',
        url: 'https://monitor.openaire.eu/dashboard/aurora'
      }
    ]
  },{
    label: 'About',
    routerLink: ['about'],
    items: [
      {
        label: 'The project',
        routerLink: ['/about/project']
      },
      {
        label: 'The models',
        routerLink: ['/about/models']
      },
      {
        label: 'The team',
        routerLink: ['/about/team']
      },
      {
        label: 'Knowledge base',
        routerLink: ['/about/faq']
      }
    ]
  }
  ]
    frameless = false;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: Params) => {
      if (params['frameless'] !== undefined) {
        this.frameless = true;
      }
    });
  }
}
