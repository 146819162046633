import {Component, OnInit} from '@angular/core';
import {ClassifierService} from "../service/classifier.service";
import {ClassificationResponse} from "../model/ClassificationResponse";
import {MessageService} from "primeng/api";
import {ClipboardService} from "ngx-clipboard";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {serviceBaseAddress} from "./app.globals";

@Component({
  selector: 'app-start',
  templateUrl: './about.component.html'
})
export class AboutComponent implements OnInit {

  public text = 'The balanced participation of women and men in education and training, the labour market and in leadership positions is crucial for gender equality in the EU. Equal access to quality education, especially tertiary education, is expected to improve chances in life for both men and women. However, women continue to be over-represented in lower paid sectors and occupations, and experience constraints in their professional choices linked to care responsibilities and gender stereotypes. The persistent employment gap is mirrored in the significant gender pay gap. Closing gender gaps in employment and pay is an urgent economic and social objective, for the individual and for society as a whole. In addition, promoting equality between women and men in decision-making has been a key objective of European policy for many years. Another important objective is the elimination of gender-based violence and protecting and supporting victims.';

  public classified = false;

  public waiting = false;

  public showingMoreInfos = false;

  public afterDraw: any;

  public classificationModel = 'aurora-sdg-multi';

  public classification: ClassificationResponse;

  public data: any;

  public chartOptions: any;

  public scriptCode = '<script src="https://' + serviceBaseAddress + '/resources/widget.js" type="text/javascript"></script>'

  public internalScriptCode = '/assets/js/widget.js'

  public htmlCode = '';

  public wheelSize = 250;

  public showBanner = true;

  items

  public modelOptions = [
    {name: 'Aurora SDG mBERT model (slower, Aurora definition of SDG\'s, 104 languages)', value: 'aurora-sdg'},
    {name: 'Aurora SDG multi-label mBERT model (fast, Aurora definition of SDG\'s, 104 languages)', value: 'aurora-sdg-multi'},
    {name: 'Elsevier SDG multi-label mBERT model (fast, Elsevier definition of SDG\'s, 104 languages)', value: 'elsevier-sdg-multi'},
    {name: 'OSDG model (alternative, OSDG definition of SDG\'s, 15 languages)', value: 'osdg'}
  ];

  constructor(private classifierService: ClassifierService,
              private route: ActivatedRoute,
              private router: Router,
              private messageService: MessageService,
              private clipboardService: ClipboardService) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: Params) => {
      if (params['model'] !== undefined) {
        this.classificationModel = params['model'];
      }
      if (params['frameless'] !== undefined) {
        this.showBanner = false;
      }
      if (params['text'] !== undefined) {
        this.text = params['text'];
        this.classifySingleAbstract();
        this.buildHtmlSnippet();
      }
      });
  }
  buildHtmlSnippet() {
    this.htmlCode = '<div class="sdg-wheel" data-wheel-height="' + this.wheelSize + '" data-model="' + this.classificationModel + '" data-text="' + this.text + '"></div>'
    this.loadWidgetScript()
  }

  classifySingleAbstract() {
    this.classified = false;
    this.waiting = true;
this.buildHtmlSnippet();
    this.classifierService.runClassifierOnSingleText(this.text, this.classificationModel).subscribe({
      next: (data) => {
        this.data = {
          labels: ['SDG 1',
            'SDG 2',
            'SDG 3',
            'SDG 4',
            'SDG 5',
            'SDG 6',
            'SDG 7',
            'SDG 8',
            'SDG 9',
            'SDG 10',
            'SDG 11',
            'SDG 12',
            'SDG 13',
            'SDG 14',
            'SDG 15',
            'SDG 16',
            'SDG 17'],
          datasets: [
            {
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              backgroundColor: [
                '#C42231', '#DDA73A', '#4E9E45', '#C31F2D', '#C63A21', '#40A8C8', '#EBBD15', '#A21C44', '#CD5E22', '#C51C75', '#DB8F20', '#BF8D2C', '#407F46', '#1F97D4', '#59BA47', '#136A9F', '#14496B'
              ],
              hoverBackgroundColor: [
                '#C42231', '#DDA73A', '#4E9E45', '#C31F2D', '#C63A21', '#40A8C8', '#EBBD15', '#A21C44', '#CD5E22', '#C51C75', '#DB8F20', '#BF8D2C', '#407F46', '#1F97D4', '#59BA47', '#136A9F', '#14496B'
              ]
            }
          ]
        };
        this.afterDraw = {
          afterDraw: chart => {
            var ctx = chart.chart.ctx;
            ctx.save();
            var image = new Image();
            image.src = 'assets/img/SDG_logo.png';
            const imageSize = 40;
            ctx.drawImage(image, chart.chart.width / 2 - imageSize / 2, chart.chart.height / 2 - imageSize / 2, imageSize, imageSize);
            ctx.restore();
          }
        }
        this.chartOptions = {
          title: {
            display: true,
            text: 'SDG Wheel',
            fontSize: 16
          },
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              callbacks: {
                label: function(context) {
                  let label = context.label || '';

                  if (label) {
                    label += ': ';
                  }
                  if (context.parsed !== null) {
                    label += (context.parsed*100).toFixed(0) + '%';
                  }
                  return label;
                }
              }
            }
          }
        };
        this.classification = data;
        this.classification.predictions.forEach(
          (value, index) => this.data.datasets[0].data[index] = value.prediction
        );
        this.classification.predictions = this.classification.predictions.sort((n1, n2) => {
          if (n1.prediction > n2.prediction) {
            return -1;
          }

          if (n1.prediction < n2.prediction) {
            return 1;
          }

          return 0;
        });
        this.classified = true;
        this.waiting = false;
        this.loadWidgetScript()
      },
      error: (error) => {
        console.log(error);
        this.waiting = false;
      }
    });
  }

  reset() {
    this.classified = false;
    this.classification = null;
  }

  copyLink() {
    const url = this.classifierService.getUrl(this.text, this.classificationModel)
    this.clipboardService.copyFromContent(url);
    this.messageService.add({
      severity: 'success',
      summary: 'Link kopiert',
      detail: 'Der Link wurde in die Zwischenablage eingefügt'
    });
  }

  getUrl() {
    return this.classifierService.getUrl(this.text, this.classificationModel);
  }

  private loadWidgetScript() {
    const node = document.createElement('script');
    node.src = this.internalScriptCode
    node.type = 'text/javascript';
    node.async = false;
    document.getElementsByTagName('head')[0].appendChild(node);
  }
}
